import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import DecodedComponent from '../../shared/DecodedComponent';
import { withRouter } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import StripeContext from './context';
import CheckoutForm from './CheckoutForm';
import TerminalForm from './TerminalForm';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});
//https://stripe.com/docs/stripe-js/react#setup

class Stripe extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.bloc;
    this.state = { initialised: false };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    let context = { bloc: this.bloc };

    const component = this.__render();

    return (
      <StripeContext.Provider value={context}>
        <>{component}</>
      </StripeContext.Provider>
    );
  }

  __render() {
    const { initialised, amount, stripePromise, stripeOptions, mode } = this.state;

    if (mode === 'terminal') {
      return <TerminalForm />;
    }

    if (stripePromise && stripeOptions) {
      return (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <CheckoutForm />
        </Elements>
      );
    }

    return <></>;
  }
}

export default withStyles(styles)(withRouter(Stripe));
