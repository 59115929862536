import { ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@mui/material';
import PageContainer from '../../common/PageContainer';
import {
  DefaultDisconnectedPageFooter,
  ScrollableQuinnContainer,
} from '../../common/ScrollableContainer';
import * as React from 'react';
import DecodedComponent from '../../shared/DecodedComponent';
import withStyles from '@mui/styles/withStyles';
import StripeContext from './context';
import { FormattedMessage } from 'react-intl';
import { notificationService } from '../../../utils/notification';
import CircularProgress from '@mui/material/CircularProgress';
import { H2TextTitleContent } from '../../shared/Typography';
import DecodedButton, { DecodedSecondaryButton } from '../../common/DecodedButton';
import { DecodedDialog, DecodedDialogTitle } from '../../shared/Dialog/Dialog';

const styles = (theme) => ({
  suggestionBtn: {
    '& span': {
      fontSize: '10px',
    },
  },
});

class CheckoutForm extends DecodedComponent {
  constructor(props) {
    super(props);

    this.bloc = props.context.bloc;

    this.state = { stripe: { working: false }, open: false };
  }

  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    this.setState({ strip: { working: true } });

    const { protocol, host } = window.location;
    const { appointment, amount } = this.state;

    const callbackUrl =
      protocol +
      '//' +
      host +
      `/callback/payment/appointment/${appointment.id}/stripe/checked-in?amount=${amount}`;

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: callbackUrl,
      },
    });

    if (result.error) {
      notificationService.error(result.error.message);
    }
  };

  __toggleDialog = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
    });
  };

  render() {
    const { amount, stripe, open } = this.state;

    return (
      <PageContainer loading={false} onBack={this.bloc.onBack}>
        <ScrollableQuinnContainer
          messageId="paymentmethod.choice.bubble"
          message="How would you like to pay for this visit?"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <H2TextTitleContent>
                <FormattedMessage
                  id={'checkout.payment.heading'}
                  defaultMessage={'Your total payment for today is:'}
                ></FormattedMessage>
              </H2TextTitleContent>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '40px !important',
                  lineHeight: '32px !important',
                  color: '#002F59',
                }}
              >
                ${amount}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <FormattedMessage
                  id={'checkout.payment.enter.details'}
                  defaultMessage={'Please enter your card details'}
                ></FormattedMessage>
              </Typography>
            </Grid>
            <Grid sx={{ paddingBottom: '48px' }} item xs={12}>
              <form id={'paymentform'} onSubmit={this.handleSubmit}>
                <PaymentElement />
              </form>
            </Grid>
          </Grid>
        </ScrollableQuinnContainer>
        <DefaultDisconnectedPageFooter justifyContent={'left'}>
          {!this.bloc.isVirtual() && (
            <Grid item xs={12} sm={6}>
              <DecodedSecondaryButton onClick={this.__toggleDialog}>
                <FormattedMessage
                  id={`payment.account.inperson.button.end`}
                  defaultMessage={'End virtual registration'}
                />
              </DecodedSecondaryButton>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Button
              disabled={stripe.working}
              variant="contained"
              color="primary"
              onClick={(e) => {
                this.handleSubmit(e);
              }}
            >
              {stripe.working ? (
                <CircularProgress size={24} sx={{}} />
              ) : (
                <FormattedMessage
                  id="checkout.payment.enter.details.submit"
                  defaultMessage={'PAY'}
                />
              )}
            </Button>
          </Grid>
        </DefaultDisconnectedPageFooter>
        <DecodedDialog
          open={open}
          onClose={this.__toggleDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DecodedDialogTitle id="alert-dialog-title">
            {'End virtual registration'}
          </DecodedDialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              End virtual registration and notified the staff of your arrival?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DecodedSecondaryButton onClick={this.__toggleDialog}>NO</DecodedSecondaryButton>
            <DecodedButton
              onClick={(e) => {
                this.bloc.end();
              }}
              autoFocus
            >
              YES
            </DecodedButton>
          </DialogActions>
        </DecodedDialog>
      </PageContainer>
    );
  }
}

export default withStyles(styles)((props) => (
  <StripeContext.Consumer>
    {(value) => {
      return (
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <CheckoutForm context={value} stripe={stripe} elements={elements} {...props} />
          )}
        </ElementsConsumer>
      );
    }}
  </StripeContext.Consumer>
));
